<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulación</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Postulaciones</li>
                  <li class="breadcrumb-item active">Asignar Vehículos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header border-0">
                <div class="row">
                  <div class="col-md-11">
                    <h5 class="title">Asignación de Vehículos</h5>
                  </div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-primary btn-sm"
                        disabled
                      >
                        <i class="fas fa-envelope"></i>
                        <strong
                          ><small><br />Enviar</small></strong
                        >
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <ul id="myTab" role="tablist" class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      id="tab-Asignaciones"
                      data-toggle="tab"
                      href="#Asignaciones"
                      class="nav-link active"
                      ref="Asignaciones"
                      >Asignaciones</a
                    >
                  </li>
                  <li class="nav-item" v-if="cant_cambios">
                    <a
                      id="tab-Cambios"
                      data-toggle="tab"
                      href="#Cambios"
                      class="nav-link"
                      >Cambios</a
                    >
                  </li>
                </ul>

                <div id="myTabContent" class="tab-content">
                  <div id="Asignaciones" class="tab-pane fade active show">
                    <div class="card-body">
                      <div class="row">
                        <div
                          class="col-md-12"
                          v-for="(postulacion, indicePost) in postulaciones"
                          :key="postulacion.id"
                        >
                          <div
                            class="card card-outline collapsed-card card-dark"
                          >
                            <div class="card-header pt-2 pb-2">
                              <h3 class="card-title">
                                <small>
                                  <b>Tipo Vehículo:</b>
                                  {{ postulacion.tep_tipos_vehiculos.nombre }}
                                </small>
                                |
                                <small>
                                  <b>Tipo Operación:</b>
                                  {{ postulacion.nTipoOperacion }}
                                  <strong>{{
                                    postulacion.area
                                      ? `(${postulacion.nArea})`
                                      : ""
                                  }}</strong>
                                </small>
                                |
                                <small>
                                  <b>Cantidad de Vehículos:</b>
                                  {{ postulacion.cantidad_vehiculo }}
                                </small>
                                |
                                <small>
                                  <b>Turno:</b>
                                  {{ postulacion.turno.nombre }}
                                </small>
                              </h3>
                              <div class="card-tools">
                                <button
                                  type="button"
                                  class="btn btn-tool"
                                  data-card-widget="collapse"
                                  data-toggle="tooltip"
                                  title="Collapse"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div class="card-body" style="display: none">
                              <div
                                class="col-md-12"
                                v-for="(
                                  itemCantVehi, indiceCantVehi
                                ) in postulacion.cantidad_vehiculo"
                                :key="indiceCantVehi"
                              >
                                <div
                                  class="card card-outline collapsed-card card-teal"
                                >
                                  <div class="card-header pt-2 pb-2">
                                    <h3 class="card-title">
                                      <small>
                                        <b
                                          >{{
                                            postulacion.tep_tipos_vehiculos
                                              .nombre
                                          }}
                                          -
                                        </b>
                                        {{ indiceCantVehi + 1 }}
                                      </small>
                                    </h3>
                                    <div class="card-tools">
                                      <button
                                        type="button"
                                        class="btn btn-tool"
                                        data-card-widget="collapse"
                                        data-toggle="tooltip"
                                        title="Collapse"
                                      >
                                        <i class="fas fa-plus"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    class="card-body table-responsive"
                                    style="display: none"
                                  >
                                    <table
                                      class="table table-bordered table-striped table-hover table-sm"
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            style="width: 5%"
                                            class="text-center"
                                          >
                                            Día
                                          </th>
                                          <th
                                            style="min-width: 15%"
                                            class="text-center"
                                          >
                                            Vehículo
                                            <input
                                              type="text"
                                              class="form-control form-control-sm"
                                              label="placa"
                                              v-model="
                                                listasForms.slct_vehiculo.placa
                                              "
                                              :id="
                                                'slct_vehiculo' +
                                                indicePost +
                                                '_' +
                                                indiceCantVehi
                                              "
                                              @change="
                                                buscarVehiculos(
                                                  postulacion.tipo_vehiculo,
                                                  null,
                                                  indicePost,
                                                  indiceCantVehi
                                                )
                                              "
                                              v-show="postulacion"
                                            />
                                          </th>
                                          <th
                                            style="min-width: 25%"
                                            class="text-center"
                                            v-if="
                                              postulacion.tipo_operacion != 3
                                            "
                                          >
                                            Conductor
                                            <input
                                              type="text"
                                              class="form-control form-control-sm"
                                              label="placa"
                                              v-model="
                                                listasForms.slct_conductor
                                                  .numero_documento
                                              "
                                              :id="
                                                'slct_conductor' +
                                                indicePost +
                                                '_' +
                                                indiceCantVehi
                                              "
                                              @change="
                                                buscarConductores(
                                                  null,
                                                  indicePost,
                                                  indiceCantVehi
                                                )
                                              "
                                            />
                                          </th>
                                          <th
                                            style="min-width: 25%"
                                            class="text-center"
                                            v-if="
                                              postulacion.tipo_operacion != 3 &&
                                              postulacion.turno.dia_turno[0]
                                                .cant_horas > 12
                                            "
                                          >
                                            Conductor 2
                                            <input
                                              type="text"
                                              class="form-control form-control-sm"
                                              label="placa"
                                              v-model="
                                                listasForms.slct_conductor_2
                                                  .numero_documento
                                              "
                                              :id="
                                                'slct_conductor_2' +
                                                indicePost +
                                                '_' +
                                                indiceCantVehi
                                              "
                                              @change="
                                                buscarConductores_2(
                                                  null,
                                                  indicePost,
                                                  indiceCantVehi
                                                )
                                              "
                                            />
                                          </th>
                                          <th
                                            style="min-width: 25%"
                                            class="text-center"
                                          >
                                            Fecha
                                          </th>
                                          <th
                                            style="width: 2%"
                                            class="text-center"
                                          >
                                            Acción
                                            <br />
                                            <button
                                              class="btn btn-default btn-sm bg-frontera-top-left text-white"
                                              :id="
                                                'buttonSave' +
                                                indicePost +
                                                '_' +
                                                indiceCantVehi
                                              "
                                              @click="
                                                savePostulacion(
                                                  postulacion.id,
                                                  postulacion.tipo_operacion,
                                                  postulacion.tep_nominacion_id,
                                                  indicePost,
                                                  indiceCantVehi
                                                )
                                              "
                                            >
                                              <i class="fas fa-save"></i>
                                            </button>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="(item, indice) in diasPost"
                                          :class="
                                            validarDias(
                                              moment(
                                                postulacion.tep_nominacion
                                                  .fecha_ini
                                              )
                                                .add(indice, 'days')
                                                .day(),
                                              postulacion.turno.dia_turno
                                            )
                                              ? ''
                                              : 'table-danger'
                                          "
                                          :key="indice"
                                          :id="
                                            'tr' +
                                            indicePost +
                                            '_' +
                                            indiceCantVehi +
                                            '_' +
                                            indice
                                          "
                                        >
                                          <td class="text-center">
                                            {{ indice + 1 }}
                                          </td>
                                          <td>
                                            <input
                                              type="text"
                                              class="form-control form-control-sm"
                                              label="placa"
                                              v-model="
                                                form1[indicePost][
                                                  indiceCantVehi
                                                ][indice].vehiculo.placa
                                              "
                                              :id="
                                                'slct_vehiculo_u' +
                                                indicePost +
                                                '_' +
                                                indiceCantVehi +
                                                '_' +
                                                indice
                                              "
                                              @change="
                                                buscarVehiculos(
                                                  postulacion.tipo_vehiculo,
                                                  indice,
                                                  indicePost,
                                                  indiceCantVehi
                                                )
                                              "
                                              v-if="
                                                form1[indicePost][
                                                  indiceCantVehi
                                                ][indice].vehiculo
                                              "
                                              :disabled="
                                                selectVehi !=
                                                indicePost +
                                                  '_' +
                                                  indiceCantVehi +
                                                  '_' +
                                                  indice
                                              "
                                            />
                                          </td>
                                          <td
                                            v-if="
                                              postulacion.tipo_operacion != 3
                                            "
                                          >
                                            <div class="row">
                                              <div class="col-md-6">
                                                <input
                                                  type="text"
                                                  class="form-control form-control-sm"
                                                  label="numero_documento"
                                                  v-model="
                                                    form1[indicePost][
                                                      indiceCantVehi
                                                    ][indice].conductor
                                                      .numero_documento
                                                  "
                                                  :id="
                                                    'slct_conductor_u' +
                                                    indicePost +
                                                    '_' +
                                                    indiceCantVehi +
                                                    '_' +
                                                    indice
                                                  "
                                                  @change="
                                                    buscarConductores(
                                                      indice,
                                                      indicePost,
                                                      indiceCantVehi
                                                    )
                                                  "
                                                  v-if="
                                                    form1[indicePost][
                                                      indiceCantVehi
                                                    ][indice].conductor
                                                  "
                                                  :disabled="
                                                    selectVehi !=
                                                    indicePost +
                                                      '_' +
                                                      indiceCantVehi +
                                                      '_' +
                                                      indice
                                                  "
                                                />
                                              </div>
                                              <div class="col-md-6">
                                                <span
                                                  v-if="
                                                    form1[indicePost][
                                                      indiceCantVehi
                                                    ][indice].conductor
                                                  "
                                                >
                                                  {{
                                                    form1[indicePost][
                                                      indiceCantVehi
                                                    ][indice].conductor.nombre
                                                  }}
                                                </span>
                                                <span v-else> </span>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            v-if="
                                              postulacion.tipo_operacion != 3 &&
                                              postulacion.turno.dia_turno[0]
                                                .cant_horas > 12
                                            "
                                          >
                                            <div class="row">
                                              <div class="col-md-6">
                                                <input
                                                  type="text"
                                                  class="form-control form-control-sm"
                                                  label="numero_documento"
                                                  v-model="
                                                    form1[indicePost][
                                                      indiceCantVehi
                                                    ][indice].conductor_2
                                                      .numero_documento
                                                  "
                                                  @change="
                                                    buscarConductores_2(
                                                      indice,
                                                      indicePost,
                                                      indiceCantVehi
                                                    )
                                                  "
                                                  :id="
                                                    'slct_conductor_2_u' +
                                                    indicePost +
                                                    '_' +
                                                    indiceCantVehi +
                                                    '_' +
                                                    indice
                                                  "
                                                  v-if="
                                                    form1[indicePost][
                                                      indiceCantVehi
                                                    ][indice].conductor_2 &&
                                                    getHorasTurno(
                                                      moment(
                                                        postulacion
                                                          .tep_nominacion
                                                          .fecha_ini
                                                      )
                                                        .add(indice, 'days')
                                                        .day(),
                                                      postulacion.turno
                                                        .dia_turno
                                                    ) &&
                                                    getHorasTurno(
                                                      moment(
                                                        postulacion
                                                          .tep_nominacion
                                                          .fecha_ini
                                                      )
                                                        .add(indice, 'days')
                                                        .day(),
                                                      postulacion.turno
                                                        .dia_turno
                                                    ) > 17
                                                  "
                                                  :disabled="
                                                    selectVehi !=
                                                    indicePost +
                                                      '_' +
                                                      indiceCantVehi +
                                                      '_' +
                                                      indice
                                                  "
                                                />
                                              </div>
                                              <div class="col-md-6">
                                                <span
                                                  v-if="
                                                    form1[indicePost][
                                                      indiceCantVehi
                                                    ][indice].conductor_2
                                                  "
                                                >
                                                  {{
                                                    form1[indicePost][
                                                      indiceCantVehi
                                                    ][indice].conductor_2.nombre
                                                  }}
                                                </span>
                                                <span v-else> </span>
                                              </div>
                                            </div>
                                          </td>
                                          <td class="text-center">
                                            <input
                                              type="text"
                                              class="form-control form-control-sm"
                                              label="fecha"
                                              v-model="
                                                form1[indicePost][
                                                  indiceCantVehi
                                                ][indice].fecha_ver
                                              "
                                              v-if="
                                                form1[indicePost][
                                                  indiceCantVehi
                                                ][indice].fecha_ver
                                              "
                                              disabled
                                            />
                                          </td>
                                          <td class="text-center">
                                            <div class="btn-group">
                                              <button
                                                class="btn btn-default btn-sm bg-navy"
                                                v-if="
                                                  $route.params.accion == 1 &&
                                                  $store.getters.can(
                                                    'tep.postulaciones.editRestDay'
                                                  ) &&
                                                  moment(
                                                    postulacion.tep_nominacion
                                                      .fecha_ini
                                                  )
                                                    .add(indice, 'days')
                                                    .format('YYYY-MM-DD') >=
                                                    hoy &&
                                                  selectVehi !=
                                                    indicePost +
                                                      '_' +
                                                      indiceCantVehi +
                                                      '_' +
                                                      indice
                                                "
                                                @click="
                                                  editDetPost(
                                                    indicePost,
                                                    indiceCantVehi,
                                                    indice,
                                                    moment(
                                                      postulacion.tep_nominacion
                                                        .fecha_ini
                                                    )
                                                      .add(indice, 'days')
                                                      .format('YYYY-MM-DD'),
                                                    postulacion.id,
                                                    postulacion.tep_nominacion_id
                                                  )
                                                "
                                              >
                                                <i class="fas fa-edit"></i>
                                              </button>
                                              <button
                                                class="btn btn-default btn-sm bg-primary"
                                                v-if="
                                                  form1[indicePost][
                                                    indiceCantVehi
                                                  ][indice].fecha >= hoy &&
                                                  selectVehi ==
                                                    indicePost +
                                                      '_' +
                                                      indiceCantVehi +
                                                      '_' +
                                                      indice
                                                "
                                                @click="
                                                  saveDetPost(
                                                    indicePost,
                                                    indiceCantVehi,
                                                    indice,
                                                    postulacion.id,
                                                    postulacion.tep_nominacion_id,
                                                    postulacion.tipo_operacion
                                                  )
                                                "
                                              >
                                                <i class="fas fa-save"></i>
                                              </button>
                                              <button
                                                class="btn btn-default btn-sm bg-danger"
                                                v-if="
                                                  $route.params.accion == 1 &&
                                                  form1[indicePost][
                                                    indiceCantVehi
                                                  ][indice].fecha > hoy &&
                                                  $store.getters.can(
                                                    'tep.postulaciones.delete'
                                                  ) &&
                                                  selectVehi !=
                                                    indicePost +
                                                      '_' +
                                                      indiceCantVehi +
                                                      '_' +
                                                      indice
                                                "
                                                @click="
                                                  deleteDetPost(
                                                    form1[indicePost][
                                                      indiceCantVehi
                                                    ][indice],
                                                    indicePost,
                                                    indiceCantVehi,
                                                    indice
                                                  )
                                                "
                                              >
                                                <i class="fas fa-trash"></i>
                                              </button>
                                            </div>
                                            <span
                                              class="badge badge-warning"
                                              v-if="
                                                form1[indicePost][
                                                  indiceCantVehi
                                                ][indice].autorizacion
                                              "
                                            >
                                              Reemplazado
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="Cambios" class="tab-pane fade" v-if="cant_cambios">
                    <div class="card-body table-responsive">
                      <PostulacionCambio ref="PostulacionCambio" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import Loading from "../../../../components/Loading";
import moment from "moment";
import "moment/locale/es";
import $ from "jquery";
import PostulacionCambio from "./PostulacionCambio";

export default {
  name: "PostulacionForm",
  components: {
    Loading,
    PostulacionCambio,
  },
  data() {
    return {
      cargando: false,
      form1: [],
      params: {},
      nominacion_id: null,
      empresa_id: null,
      postulaciones: [],
      cambios: [],
      cant_cambios: false,
      slctVehi: null,
      listasForms: {
        slct_vehiculo: {},
        slct_conductor: {},
        slct_conductor_2: {},
      },
      vehiculos: [],
      conductores: [],
      conductores_2: [],
      diasPost: null,
      hoy: moment().format("YYYY-MM-DD"),
      postEmpresa: [],
      moment: moment,
      selectVehi: null,
    };
  },

  async beforeMount() {
    await this.init();
  },

  computed: {
    ...mapState("modTep", ["postulacionTepShow", "loading"]),

    time: function () {
      return this.date.format("mm:ss");
    },
  },

  methods: {
    ...mapActions("modOperacion", ["actGetListas"]),
    ...mapActions("modTep", [
      "actGetPostulacionesShow",
      "actSavePostulacion",
      "actSavePostulacionDet",
      "actEditPostulacion",
      "actDeletePostulacion",
    ]),

    async init() {
      this.cargando = true;
      this.params = {
        empresa_id: this.$route.params.empresa_id,
        nominacion_id: this.$route.params.nominacion.id,
      };
      await this.actGetPostulacionesShow(this.params).then(async () => {
        this.postulaciones = await this.postulacionTepShow.asignaciones;

        // Valores para los cambios autorizados
        this.cambios = await this.postulacionTepShow.cambios;
        this.cant_cambios =
          (await this.cambios[0].tep_det_postulacion.length) > 0;
        this.cant_cambios == 0 ? this.$refs.Asignaciones.click() : null;

        var fecha1 = await moment(
          this.postulaciones[0].tep_nominacion.fecha_ini
        );
        var fecha2 = await moment(
          this.postulaciones[0].tep_nominacion.fecha_fin
        );
        this.diasPost = (await fecha2.diff(fecha1, "days")) + 1;
        await this.buildArrayForm();
        this.cargando = false;
      });
      this.cargando = false;
    },

    async buildArrayForm() {
      let arrayPost = [];
      for (let i = 0; i < this.postulaciones.length; i++) {
        let arrayCant = [];
        for (let j = 0; j < this.postulaciones[i].cantidad_vehiculo; j++) {
          let arrayDias = [];
          for (let k = 1; k <= this.diasPost; k++) {
            let form = {
              vehiculo: null,
              conductor: null,
              conductor_2: null,
              fecha: null,
              indice_opracion: i,
              indice_veh: j,
              pos_dia: k,
              estado: 1,
            };

            if (this.$route.params.accion == 2) {
              $("#buttonSave" + i + "_" + j).prop("disabled", true);
              $("#slct_vehiculo" + i + "_" + j).prop("disabled", true);
              $("#slct_conductor" + i + "_" + j).prop("disabled", true);
            }

            this.postulaciones[i].tep_det_postulacion.forEach(
              (tep_det_post) => {
                if (
                  tep_det_post.indice_veh == j &&
                  tep_det_post.pos_dia == k &&
                  this.postulaciones[i].id == tep_det_post.det_nominacion_id
                ) {
                  let conductor = null;
                  if (tep_det_post.conductor_id && tep_det_post.conductor) {
                    conductor = {
                      id: tep_det_post.conductor_id,
                      numero_documento: tep_det_post.conductor.numero_documento,
                      nombre:
                        tep_det_post.conductor.nombres +
                        " " +
                        tep_det_post.conductor.apellidos,
                    };
                  }
                  let conductor_2 = null;
                  if (tep_det_post.conductor_2_id && tep_det_post.conductor2) {
                    conductor_2 = {
                      id: tep_det_post.conductor_2_id,
                      numero_documento:
                        tep_det_post.conductor2.numero_documento,
                      nombre:
                        tep_det_post.conductor2.nombres +
                        " " +
                        tep_det_post.conductor2.apellidos,
                    };
                    if (this.$route.params.accion == 2) {
                      $("#slct_conductor_2" + i + "_" + j).prop(
                        "disabled",
                        true
                      );
                    }
                  }
                  form = {
                    id: tep_det_post.id,
                    vehiculo: {
                      id: tep_det_post.vehiculo_id,
                      placa: tep_det_post.vehiculo.placa,
                    },
                    conductor,
                    autorizacion: tep_det_post.tep_det_postulaciones.length > 0,
                    conductor_2: conductor_2,
                    fecha_ver: moment(tep_det_post.fecha).format("dddd, LL"),
                    fecha: tep_det_post.fecha,
                    indice_opracion: i,
                    indice_veh: j,
                    pos_dia: k,
                    estado: tep_det_post.estado,
                  };
                }
              }
            );
            arrayDias.push(form);
          }
          arrayCant.push(arrayDias);
        }
        arrayPost.push(arrayCant);
      }
      this.form1 = arrayPost;
    },

    editDetPost(indicePost, indiceVehi, indiceDia, fecha, tepDet, tepNomi) {
      if (!this.form1[indicePost][indiceVehi][indiceDia].conductor) {
        this.form1[indicePost][indiceVehi][indiceDia].conductor = {};
      }

      if (!this.form1[indicePost][indiceVehi][indiceDia].conductor_2) {
        this.form1[indicePost][indiceVehi][indiceDia].conductor_2 = {};
      }

      if (!this.form1[indicePost][indiceVehi][indiceDia].vehiculo) {
        this.form1[indicePost][indiceVehi][indiceDia].vehiculo = {};
      }

      if (this.form1[indicePost][indiceVehi][indiceDia].estado !== 2) {
        this.form1[indicePost][indiceVehi][indiceDia].fecha = fecha;
        this.form1[indicePost][indiceVehi][indiceDia].fecha_ver =
          moment(fecha).format("dddd, LL");
        this.form1[indicePost][indiceVehi][indiceDia].tep_nominacion = tepNomi;
        this.form1[indicePost][indiceVehi][indiceDia].tep_det_nominacion =
          tepDet;
        this.selectVehi = indicePost + "_" + indiceVehi + "_" + indiceDia;
      } else {
        this.$swal({
          icon: "error",
          title: "Postulacion usada, no se puede editar",
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    async saveDetPost(
      indicePost,
      indiceVehi,
      indiceDia,
      tepDet,
      tepNomi,
      tipoOperacion
    ) {
      this.cargando = true;
      let id = null;
      if (this.form1[indicePost][indiceVehi][indiceDia].id) {
        id = this.form1[indicePost][indiceVehi][indiceDia].id;
      }
      this.form1[indicePost][indiceVehi][indiceDia].id = await id;
      this.form1[indicePost][indiceVehi][indiceDia].tep_nominacion =
        await tepNomi;
      this.form1[indicePost][indiceVehi][indiceDia].tep_det_nominacion =
        await tepDet;
      let formEnvio = [];
      formEnvio.push(await this.form1[indicePost][indiceVehi][indiceDia]);
      if (
        (tipoOperacion != 3 &&
          this.validarForm(indicePost, formEnvio, tipoOperacion)) ||
        tipoOperacion == 3
      ) {
        let datos = { datos: this.form1[indicePost][indiceVehi][indiceDia] };
        await this.actSavePostulacionDet(datos).then((response) => {
          this.cargando = false;
          if (response.msg) {
            this.form1[indicePost][indiceVehi][indiceDia].id =
              response.tepPost.id;
            this.form1[indicePost][indiceVehi][indiceDia].conductor.nombre =
              response.conductor[0].nombres +
              " " +
              response.conductor[0].apellidos;
            this.$swal({
              icon: "success",
              title: response.msg,
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.selectVehi = null;
          }
        });
      } else {
        this.cargando = false;
      }
    },

    async deleteDetPost(postDelete, post, veh, dia) {
      this.$swal({
        title: "Está seguro de eliminar este detalle de la postulación?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then(async (result) => {
        if (result.value) {
          this.cargando = true;
          await axios
            .delete("/api/tep/postulaciones/" + postDelete.id)
            .then(() => {
              this.cargando = false;
              this.$swal({
                icon: "success",
                title:
                  "Se eliminó el detalle de la postulación exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
          this.form1[post][veh][dia].vehiculo = null;
          this.form1[post][veh][dia].conductor = null;
          this.form1[post][veh][dia].conductor_2 = null;
          this.form1[post][veh][dia].fecha = null;
          this.form1[post][veh][dia].fecha_ver = null;
          this.form1[post][veh][dia].indice_opracion = post;
          this.form1[post][veh][dia].indice_veh = veh;
          this.form1[post][veh][dia].pos_dia = dia + 1;
          this.form1[post][veh][dia].estado = 1;
        }
      });
    },

    validarDias(diaSemana, numDias) {
      if (diaSemana == 0) {
        diaSemana = 7;
      }
      let search = numDias.find((d) => d.dia_semana === diaSemana);
      if (search != undefined) {
        return true;
      }
      return false;
    },

    getHorasTurno(diaSemana, numDias) {
      if (diaSemana == 0) {
        diaSemana = 7;
      }
      let search = numDias.find((d) => d.dia_semana === diaSemana);
      if (search != undefined) {
        return search.cant_horas;
      }
      return null;
    },

    async buscarVehiculos(tipo_vehiculo, dia, post, cantVehi) {
      this.cargando = true;
      let dato = null;
      let fecha = null;
      if (dia != null) {
        dato = this.form1[post][cantVehi][dia].vehiculo.placa;
        fecha = this.form1[post][cantVehi][dia].fecha;
      } else {
        dato = this.listasForms.slct_vehiculo.placa;
        fecha = this.postulaciones[0].tep_nominacion.fecha_fin;
      }

      let res = await this.validarStatus(dato, "V", fecha);
      if (res && res.length != 0) {
        if (res.validacion.error) {
          this.cargando = false;
          await this.msgDocumentacion(res);
          if (
            res.validacion.error.documentos.obligatorios !== undefined ||
            res.validacion.error.documentos.vencidos !== undefined ||
            res.validacion.error.documentos.no_verificados !== undefined
          ) {
            if (dia != null) {
              this.form1[post][cantVehi][dia].vehiculo = {};
            } else {
              this.listasForms.slct_vehiculo = {};
            }
            return false;
          }
        }

        /*  if (res.inspeccion != null) {
          if (res.inspeccion.estado == 4) {
            if (dia != null) {
              this.form1[post][cantVehi][dia].vehiculo = {};
            } else {
              this.listasForms.slct_vehiculo = {};
            }
            await this.$swal({
              icon: "error",
              title: `La inspección del vehículo se encuentra en estado rechazado`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          } else if (res.inspeccion.estado == 5) {
            await this.$swal({
              icon: "success",
              title: `La inspección del vehículo se encuentra aprobada con novedad`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
          } else if (res.inspeccion.estado == 2) {
            await this.$swal({
              icon: "success",
              title: `La inspección del vehículo se encuentra aprobada`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });

          } else if (res.inspeccion.estado == 3) {
            await this.$swal({
              icon: "success",
              title: `La inspección del vehículo se encuentra autorizada`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
          }
        } else {
          if (dia != null) {
            this.form1[post][cantVehi][dia].vehiculo = {};
          } else {
            this.listasForms.slct_vehiculo = {};
          }
          this.$swal({
            icon: "error",
            title: `El vehículo no cuenta con inspección vigente`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        if (res.postulacion) {
          if (dia != null) {
            this.form1[post][cantVehi][dia].vehiculo = {};
          } else {
            this.listasForms.slct_vehiculo = {};
          }
          this.$swal({
            icon: "error",
            title: `El vehículo excede las horas de postulacion asignadas`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }

        if (res.turno.length > 0) {
          if (dia != null) {
            this.form1[post][cantVehi][dia].vehiculo = {};
          } else {
            this.listasForms.slct_vehiculo = {};
          }
          this.$swal({
            icon: "error",
            title: `El vehículo ya está en otro turno para la fecha $`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }

        if (res["info"] == false) {
          this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Vehiculos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          if (dia != null) {
            this.form1[post][cantVehi][dia].vehiculo = {};
          } else {
            this.listasForms.slct_vehiculo = {};
          }
          this.cargando = false;
          return false;
        }

        if (!res["has_lineas"]) {
          this.$swal({
            icon: "error",
            title: `El vehiculo no se encuentra en la linea de negocio Transporte especial de pasajeros`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          if (dia != null) {
            this.form1[post][cantVehi][dia].vehiculo = {};
          } else {
            this.listasForms.slct_vehiculo = {};
          }
          this.cargando = false;
          return false;
        }

        if (res[0].tipo_vehiculo_id != tipo_vehiculo) {
          if (dia != null) {
            this.form1[post][cantVehi][dia].vehiculo = {};
          } else {
            this.listasForms.slct_vehiculo = {};
          }
          this.$swal({
            icon: "error",
            title: `El tipo de vehículo no coincide.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }

        if (!res.modelo) {
          if (dia != null) {
            this.form1[post][cantVehi][dia].vehiculo = {};
          } else {
            this.listasForms.slct_vehiculo = {};
          }
          this.$swal({
            icon: "error",
            title: "El modelo del vehiculo no cumple con los requisitos.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }

        //Valida reporte satelital del vehiculo
        if (res.ultimoRegistro) {
          if (res.ultimoRegistro.error) {
            if (dia != null) {
              this.form1[post][cantVehi][dia].vehiculo = {};
            } else {
              this.listasForms.slct_vehiculo = {};
            }
            this.$swal({
              icon: "error",
              title: `Error GPS: ${res.ultimoRegistro.error.msg}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          } else if (res.ultimoRegistro.success) {
            await this.selectAllVehi(post, dia, cantVehi, res[0]);
            this.$swal({
              icon: "success",
              title: `${res.ultimoRegistro.success.msg} Distancia: ${res.ultimoRegistro.success.distance} Km`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.cargando = false;
          }
        }
      } else {
        this.$swal({
          icon: "error",
          title:
            "El vehículo digitado NO se encuentra registrado, pruebe con otra placa.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.cargando = false;
        if (dia != null) {
          this.form1[post][cantVehi][dia].vehiculo = {};
        } else {
          this.listasForms.slct_vehiculo = {};
        }
        return false;
      }

      this.$swal({
        icon: "success",
        title:
          "El vehiculo cumple con los requisitos para poder ser postulado...",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
      });
      await this.selectAllVehi(post, dia, cantVehi, res[0]);
      this.cargando = false;
    },

    async buscarConductores(dia, post, cantVehi) {
      this.cargando = true;
      let dato = null;
      let fecha = null;
      if (dia != null) {
        dato = this.form1[post][cantVehi][dia].conductor.numero_documento;
        fecha = this.form1[post][cantVehi][dia].fecha;
      } else {
        dato = this.listasForms.slct_conductor.numero_documento;
        fecha = this.postulaciones[0].tep_nominacion.fecha_fin;
      }

      let res = await this.validarStatus(dato, "C", fecha);

      // Se valida si existe el conductor
      if (res && res.length != 0) {
        if (res.validacion.error) {
          this.cargando = false;
          await this.msgDocumentacion(res);
          if (
            res.validacion.error.documentos.obligatorios !== undefined ||
            res.validacion.error.documentos.vencidos !== undefined ||
            res.validacion.error.documentos.no_verificados !== undefined
          ) {
            if (dia != null) {
              this.form1[post][cantVehi][dia].conductor = {};
            } else {
              this.listasForms.slct_conductor = {};
            }
            return false;
          }
        }

        if (res[0]) {
          if (res["info"] == false) {
            this.$swal({
              icon: "error",
              title: `Hacen falta campos por diligenciar en el formulario de creacion de Conductores`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            if (dia != null) {
              this.form1[post][cantVehi][dia].conductor = {};
            } else {
              this.listasForms.slct_conductor = {};
            }
            this.cargando = false;
            return false;
          }

          if (!res["has_lineas"]) {
            this.$swal({
              icon: "error",
              title: `El Conductor no se encuentra en la linea de negocio Transporte especial de pasajeros`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            if (dia != null) {
              this.form1[post][cantVehi][dia].conductor = {};
            } else {
              this.listasForms.slct_conductor = {};
            }
            this.cargando = false;
            return false;
          }

          // Se valida que el conductor no tenga alguna postulación activa
          if (res.postulacion.length > 0) {
            if (dia != null) {
              this.form1[post][cantVehi][dia].conductor = {};
            } else {
              this.listasForms.slct_conductor = {};
            }
            this.$swal({
              icon: "error",
              title: `El conductor ya está postulado para la fecha $`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          }

          // Se valida que el conductor no tenga algún turno activo
          if (res.turno && res.turno.length > 0) {
            if (dia != null) {
              this.form1[post][cantVehi][dia].conductor = {};
            } else {
              this.listasForms.slct_conductor = {};
            }
            this.$swal({
              icon: "error",
              title: `El conductor ya está en otro turno para la fecha $`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          }

          // Si el conductor es válido
          this.$swal({
            icon: "success",
            title:
              "El Conductor cumple con los requisitos para poder ser postulado...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          await this.selectAllCond(post, dia, cantVehi, res[0]);
        }
      } else {
        this.$swal({
          icon: "error",
          title:
            "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.cargando = false;
        if (dia != null) {
          this.form1[post][cantVehi][dia].conductor = {};
        } else {
          this.listasForms.slct_conductor = {};
        }
        return false;
      }
      this.cargando = false;
    },

    async buscarConductores_2(dia, post, cantVehi) {
      this.cargando = true;
      let dato = null;
      let fecha = null;
      if (dia != null) {
        dato = this.form1[post][cantVehi][dia].conductor_2.numero_documento;
        fecha = this.form1[post][cantVehi][dia].fecha;
      } else {
        dato = this.listasForms.slct_conductor_2.numero_documento;
        fecha = this.postulaciones[0].tep_nominacion.fecha_fin;
      }

      let res = await this.validarStatus(dato, "C2", fecha);

      // Se valida si existe el conductor
      if (res && res.length != 0) {
        if (res.validacion.error) {
          this.cargando = false;
          await this.msgDocumentacion(res);
          if (
            res.validacion.error.documentos.obligatorios !== undefined ||
            res.validacion.error.documentos.vencidos !== undefined ||
            res.validacion.error.documentos.no_verificados !== undefined
          ) {
            if (dia != null) {
              this.form1[post][cantVehi][dia].conductor_2 = {};
            } else {
              this.listasForms.slct_conductor_2 = {};
            }
            return false;
          }
        }

        if (res[0]) {
          if (res["info"] == false) {
            this.$swal({
              icon: "error",
              title: `Hacen falta campos por diligenciar en el formulario de creacion de Conductores`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            if (dia != null) {
              this.form1[post][cantVehi][dia].conductor_2 = {};
            } else {
              this.listasForms.slct_conductor_2 = {};
            }
            this.cargando = false;
            return false;
          }

          if (!res["has_lineas"]) {
            this.$swal({
              icon: "error",
              title: `El Conductor 2 no se encuentra en la linea de negocio Transporte especial de pasajeros`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            if (dia != null) {
              this.form1[post][cantVehi][dia].conductor_2 = {};
            } else {
              this.listasForms.slct_conductor_2 = {};
            }
            this.cargando = false;
            return false;
          }

          // Se valida que el conductor no tenga alguna postulación activa
          if (res.postulacion.length > 0) {
            if (dia != null) {
              this.form1[post][cantVehi][dia].conductor_2 = {};
            } else {
              this.listasForms.slct_conductor_2 = {};
            }
            this.$swal({
              icon: "error",
              title: `El conductor 2 ya está postulado para la fecha $`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          }

          // Se valida que el conductor no tenga algún turno activo
          if (res.turno && res.turno.length > 0) {
            if (dia != null) {
              this.form1[post][cantVehi][dia].conductor_2 = {};
            } else {
              this.listasForms.slct_conductor_2 = {};
            }
            this.$swal({
              icon: "error",
              title: `El conductor 2 ya está en otro turno para la fecha $`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          }

          // Si el conductor es válido
          this.$swal({
            icon: "success",
            title:
              "El Conductor cumple con los requisitos para poder ser postulado...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          await this.selectAllCond_2(post, dia, cantVehi, res[0]);
        }
      } else {
        this.$swal({
          icon: "error",
          title:
            "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.cargando = false;
        if (dia != null) {
          this.form1[post][cantVehi][dia].conductor2 = {};
        } else {
          this.listasForms.slct_conductor_2 = {};
        }
        return false;
      }
      this.cargando = false;
    },

    async savePostulacion(tepDet, tipoOperacion, tepNomi, post, canVehi) {
      this.cargando = true;
      let formEnvio = [];
      let arrayVehi = this.form1[post][canVehi];
      for (let i = 0; i < arrayVehi.length; i++) {
        if (arrayVehi[i].vehiculo != null) {
          let id = null;
          if (arrayVehi[i].id) {
            id = arrayVehi[i].id;
          }
          let form = {
            id,
            vehiculo: arrayVehi[i].vehiculo,
            conductor: arrayVehi[i].conductor,
            conductor_2: arrayVehi[i].conductor_2,
            fecha: arrayVehi[i].fecha,
            tep_det_nominacion: tepDet,
            tep_nominacion: tepNomi,
            indice_veh: arrayVehi[i].indice_veh,
            pos_dia: arrayVehi[i].pos_dia,
            estado: arrayVehi[i].estado,
          };
          formEnvio.push(form);
        }
      }
      if (
        (tipoOperacion != 3 &&
          this.validarForm(post, formEnvio, tipoOperacion)) ||
        tipoOperacion == 3
      ) {
        let datos = { datos: formEnvio };
        await this.actSavePostulacion(datos).then((response) => {
          if (response.msg) {
            this.$swal({
              icon: "success",
              title: response.msg,
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          }
        });
        await this.init();
      }
      this.cargando = false;
    },

    validarForm(post, form, tipoOperacion) {
      let flag = true;
      if (tipoOperacion != 3) {
        if (form.length > 0) {
          form.forEach((data) => {
            let dia_turno = this.getHorasTurno(
              moment(data.fecha).day(),
              this.postulaciones[post].turno.dia_turno
            );
            // Se valida si es necesario validar los dos conductores
            if (dia_turno > 23) {
              // Se valida si todos los campos se encuentran diligenciados
              if (data.vehiculo) {
                if (!data.vehiculo.id) {
                  this.$swal({
                    icon: "error",
                    title: `Debe diligenciar el vehículo`,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  flag = false;
                }
              } else {
                this.$swal({
                  icon: "error",
                  title: `Debe diligenciar el vehículo`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                flag = false;
              }

              if (data.conductor) {
                if (!data.conductor.id) {
                  this.$swal({
                    icon: "error",
                    title: `Debe diligenciar el conductor 1`,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  flag = false;
                }
              } else {
                this.$swal({
                  icon: "error",
                  title: `Debe diligenciar el conductor 1`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                flag = false;
              }

              if (data.conductor_2) {
                if (!data.conductor_2.id) {
                  this.$swal({
                    icon: "error",
                    title: `Debe diligenciar el conductor 2`,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  flag = false;
                }
              } else {
                this.$swal({
                  icon: "error",
                  title: `Debe diligenciar el conductor 2`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                flag = false;
              }

              if (data.conductor && data.conductor_2) {
                if (data.conductor.id === data.conductor_2.id) {
                  this.$swal({
                    icon: "error",
                    title: `Los conductores de la postulación deben ser diferentes.`,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  flag = false;
                }
              }
            } else {
              if (!data.vehiculo || !data.conductor) {
                this.$swal({
                  icon: "error",
                  title: `Debe diligenciar el vehículo y el conductor para todas las fechas.`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                flag = false;
              }
            }
          });
        } else {
          this.$swal({
            icon: "error",
            title: `Debe diligenciar todos los campos para realizar la postulacion.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          flag = false;
        }
      }
      return flag;
    },

    async validarStatus(dato, tipo, fecha) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "api/tep/postulaciones/listaConductores";
      } else if (tipo == "C2") {
        entidad = "Conductor";
        url = "api/tep/postulaciones/listaConductores";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "api/tep/postulaciones/listaVehiculos";
      }

      let params = {
        entidad,
        dato,
        fecha: fecha,
        sitio_id: this.postulaciones[0].tep_nominacion.sitio_id,
        cant_horas: this.postulaciones[0].turno.dia_turno[0].cant_horas,
      };

      var res = null;

      await axios
        .get(url, {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function (e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    async selectAllVehi(post, dia, cantVehi, vehiculo) {
      this.listasForms.slct_vehiculo = {};
      if (dia != null) {
        this.form1[post][cantVehi][dia].vehiculo = {
          placa: vehiculo.placa,
          id: vehiculo.id,
        };
      } else {
        for (let i = 0; i < this.diasPost; i++) {
          let fecha_nominacion = moment(
            this.postulaciones[post].tep_nominacion.fecha_ini
          )
            .add(i, "days")
            .format("YYYY-MM-DD");

          // Se valida si esta dentro de la fecha permitida
          if (fecha_nominacion >= this.hoy) {
            let diasSemana = moment(
              this.postulaciones[post].tep_nominacion.fecha_ini
            )
              .add(i, "days")
              .day();
            let numDias = this.postulaciones[post].turno.dia_turno;

            let res = await this.validarDias(diasSemana, numDias);
            if (res == true) {
              this.form1[post][cantVehi][i].vehiculo = {
                placa: vehiculo.placa,
                id: vehiculo.id,
              };
              this.form1[post][cantVehi][i].fecha = fecha_nominacion;
            }
          }
        }
      }
    },

    async selectAllCond(post, dia, cantVehi, conductor) {
      this.listasForms.slct_conductor = {};
      if (dia != null) {
        this.form1[post][cantVehi][dia].conductor = {
          numero_documento: conductor.numero_documento,
          id: conductor.id,
        };
      } else {
        for (let i = 0; i < this.diasPost; i++) {
          let fecha_nominacion = moment(
            this.postulaciones[post].tep_nominacion.fecha_ini
          )
            .add(i, "days")
            .format("YYYY-MM-DD");
          // Se valida si esta dentro de la fecha permitida
          if (fecha_nominacion >= this.hoy) {
            let diasSemana = moment(
              this.postulaciones[post].tep_nominacion.fecha_ini
            )
              .add(i, "days")
              .day();
            let numDias = this.postulaciones[post].turno.dia_turno;
            let res = await this.validarDias(diasSemana, numDias);
            if (res == true) {
              this.form1[post][cantVehi][i].conductor = {
                numero_documento: conductor.numero_documento,
                id: conductor.id,
              };
            }
          }
        }
      }
    },

    async selectAllCond_2(post, dia, cantVehi, conductor) {
      this.listasForms.slct_conductor_2 = {};
      if (dia != null) {
        this.form1[post][cantVehi][dia].conductor_2 = {
          numero_documento: conductor.numero_documento,
          id: conductor.id,
        };
      } else {
        for (let i = 0; i < this.diasPost; i++) {
          let fecha_nominacion = moment(
            this.postulaciones[post].tep_nominacion.fecha_ini
          )
            .add(i, "days")
            .format("YYYY-MM-DD");
          // Se valida si esta dentro de la fecha permitida
          if (fecha_nominacion >= this.hoy) {
            let diasSemana = moment(
              this.postulaciones[post].tep_nominacion.fecha_ini
            )
              .add(i, "days")
              .day();
            let numDias = this.postulaciones[post].turno.dia_turno;
            let res = await this.validarDias(diasSemana, numDias);
            if (res == true) {
              this.form1[post][cantVehi][i].conductor_2 = {
                numero_documento: conductor.numero_documento,
                id: conductor.id,
              };
            }
          }
        }
      }
    },

    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let erroHtml = "<div>";
        if (res.validacion.error.documentos) {
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml +=
              res.validacion.error.documentos.obligatorios.join("<br>");
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml +=
              res.validacion.error.documentos.no_verificados.join("<br>");
          }
        }
        erroHtml += "</div>";
        await this.$swal({
          html: erroHtml,
          showCancelButton: false,
          focusConfirm: true,
          icon: "error",
          title: "Aviso en documentacion",
          showConfirmButton: false,
          showCloseButton: true,
          footer:
            "<small style='text-align:center'><b>Desde el 1 de octubre se aplicaran las restricciones</b>, por favor tenga al dia la documentacion de sus vehiculos, para mas informacion comuniquese con la mesa de ayuda al siguiente numero <b>3176431376 Opción 3</b></small>",
        });
      }
    },

    back() {
      return this.$router.replace("/Tep/Postulaciones");
    },
  },
};
</script>
